import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
        <p>© 2023 Coded by Inga Zhuravleva</p>
    </div>
  )
}

export default Footer;